<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="FAQ" slot="component">
      <div class="FAQ-head">
        <p>{{$t('frequently asked questions')}}</p>
        <!-- <div class="FAQ-head-btn-contain">
          <button class="button-primary btn" @click="contactSupport">CONTACT SUPPORT</button>
        </div>-->
      </div>
      <div class="FAQ-box">
        <Loading v-if="showLoader" class="vlive-loader"></Loading>
        <div class="FAQ-container" v-else>
          <div
            class="vlive-faqs"
            v-for="(faq, i) in dummyfaqs"
            :key="i"
            :id="`faq-data-${i}`"
            :class="isActive(i)"
            :data-action="getDataAction(i)"
          >
            <div class="vlive-faq-questions" @click="toggleAnswers(i)">
              <p
                class="questions"
                :style="[localDisplayLang === 'ara' ? {'float':'right'} : {'float':'left'}]"
              >{{ faq.faqquestion }}</p>
              <div
                class="faq-collapse"
                :style="[localDisplayLang === 'ara' ? {'float':'left'} : {'float':'right'}]"
              >
                <img :src="getCollapseIcon(i)" class="collapse-icon" :id="`collapse-icon-${i}`" />
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="vlive-faq-answers" :id="`faq-ans-${i}`">
              <p class="answers">{{ faq.faqanswer }}</p>
            </div>
          </div>
          <!-- <div class="FAQ-foot">
            <div class="FAQ-foot-btn-contain">
              <button
                class="button-primary btn"
                :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
                @click="contactSupport"
              >CONTACT SUPPORT</button>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";
export default {
  props: ["closePopup"],
  data() {
    return {
      showLoader: true,
      width: "55%",
      margin: "5% auto",
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      radiusType: "full",
      dummyfaqs: [], //later it will store the API JSON Data
      upArrow: require("@/assets/icons/down_arrow.svg"),
      downArrow: require("@/assets/icons/up_arrow.svg"),
      providerUniqueId: _providerId,
      localDisplayLang: null
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "getRtl"])
  },
  methods: {
    contactSupport() {
      // eventBus.$emit("show-contact-support", true);
      this.closePopup();
      document.getElementById("body").style.overflow = "hidden";
      if (!this.subscriberid) {
        let payload = {
          state: true,
          formType: "lookup"
        };
        eventBus.$emit("authPopup", payload);
      } else {
        eventBus.$emit("create-ticket-popup", true);
      }
    },
    toggleAnswers(index) {
      let ques = document.getElementsByClassName("vlive-faqs");
      if (ques.length > 0) {
        for (let i = 0; i < ques.length; i++) {
          if (i !== index) {
            ques[i].classList.remove("active");
            let clickedQues = document.getElementById(`faq-data-${i}`);
            // let action = clickedQues.getAttribute("data-action");
            let collapseIcon = document.getElementById(`collapse-icon-${i}`);
            clickedQues.classList.remove("active");
            clickedQues.setAttribute("data-action", "add");
            collapseIcon.src = this.upArrow;
          }
        }
      }
      let clickedQues = document.getElementById(`faq-data-${index}`);
      if (!clickedQues) return;
      let action = clickedQues.getAttribute("data-action");
      let collapseIcon = document.getElementById(`collapse-icon-${index}`);
      if (action == "minus") {
        clickedQues.classList.remove("active");
        clickedQues.setAttribute("data-action", "add");
        collapseIcon.src = this.upArrow;
      } else {
        clickedQues.classList.add("active");
        clickedQues.setAttribute("data-action", "minus");
        collapseIcon.src = this.downArrow;
      }
    },
    getCollapseIcon(index) {
      return this.upArrow;
    },
    getDataAction(index) {
      return "plus";
    },
    isActive(index) {
      return "";
    },
    declareDummyFaqs() {
      // console.log("faq url", this.appConfig.providerDetails.faqUrl);
      fetch(
        this.localDisplayLang === "ara"
          ? this.appConfig.providerDetails.faqUrl.ar
          : this.appConfig.providerDetails.faqUrl.default,
        {
          method: "GET"
        }
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(response => {
          // console.log("response faq", response.faqdata);
          this.dummyfaqs = response.faqdata;
          this.showLoader = false;
        });
    }
  },
  mounted() {
    this.declareDummyFaqs();
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", data => {
      this.localDisplayLang = data;
    });
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      )
    // ArrowFaq: () =>
    //   import(
    //     /* webpackChunkName: "arrowsvg" */ "@/components/SvgImages/ArrowFaq.vue"
    //   )
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.FAQ {
  font-family: $font-regular;
  &-head {
    // display: flex;
    justify-content: space-between;
    position: relative;
    padding: 1.2rem 3.2rem;
    p {
      color: $clr-light-gd3;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2rem;
    }
    .btn {
      font-size: 0.6rem;
      margin-right: 2.1rem;
    }
  }
  // &-foot {

  // }
  &-box {
    .vlive-loader {
      padding-top: 6rem;
    }
  }
  &-container {
    padding: 1.8rem 3.1rem;
    background-color: $clr-dark-gd3;
    height: 70vh;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    overflow-y: auto;
    .vlive-faqs {
      border-bottom: 1px solid $clr-popup-label;
      margin-bottom: 0.6rem;
      &:last-child {
        border: none;
      }
      &:hover {
        .vlive-faq-questions {
          p {
            transform: translateX(0.3rem);
            transition-timing-function: ease-in-out;
            transition: 0.3s;
          }
          .faq-collapse {
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    .vlive-faq-questions {
      cursor: pointer;
      padding: 1.2rem 0;
      p {
        color: #b0afb5;
        font-family: $font-regular;
        // float: left;
        width: 72%;
        font-size: 0.9rem;
      }
      .faq-collapse {
        // float: right;
        img {
          vertical-align: middle;
          opacity: 0.7;
        }
      }
    }
    .vlive-faq-answers {
      height: 0;
      opacity: 0;
      overflow: hidden;
      color: white !important;
      font-family: $font-regular;
      transition: height 0s, opacity 0.3s ease;
      p {
        width: 72%;
        font-size: 0.9rem;
      }
    }
    .active {
      .vlive-faq-answers {
        height: auto;
        opacity: 1;
        padding: 1.2rem;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .FAQ-foot {
      &-btn-contain {
        width: 35%;
        margin: 0 auto;
      }
    }
  }
}
@media only screen and (min-width: 320px) {
  .FAQ {
    &-head {
      padding: 1.8rem 1.5rem 1.8rem 1rem;
      &-btn-contain {
        margin-right: 1rem;
      }
      p {
        font-size: 1rem;
        line-height: 1rem;
      }
      .btn {
        padding: 0.2rem;
        font-size: 0.4rem;
      }
    }
    &-box {
      height: 50vh;
    }
    &-container {
      padding: 1.8rem 1rem;
      height: 50vh;
      .vlive-faq-questions {
        padding: 0.8rem 0rem;
        p {
          font-size: 0.8rem;
          width: 80%;
        }
      }
      .vlive-faq-answers {
        p {
          font-size: 0.8rem;
          width: 91%;
        }
      }
      .active {
        .vlive-faq-answers {
          padding: 0.8rem;
        }
      }
      .FAQ-foot {
        &-btn-contain {
          width: 46%;
          margin: 0 auto;
          button {
            font-size: 0.6rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .FAQ {
    &-head {
      padding: 1.8rem 1.5rem;
      &-btn-contain {
        margin-right: 2rem;
      }
      p {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      .btn {
        padding: 0.4rem;
        font-size: 0.6rem;
      }
    }
    &-box {
      height: 60vh;
    }
    &-container {
      padding: 1.8rem 1.5rem;
      height: 60vh;
      .vlive-faq-questions {
        padding: 1rem 0rem;
        p {
          font-size: 0.8rem;
          width: 80%;
        }
      }
      .vlive-faq-answers {
        p {
          font-size: 0.8rem;
          width: 90%;
        }
      }
      .active {
        .vlive-faq-answers {
          padding: 1rem;
        }
      }
      .FAQ-foot {
        &-btn-contain {
          width: 45%;
          margin: 0 auto;
          button {
            font-size: 0.7rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .FAQ {
    &-head {
      padding: 1.8rem 3rem;
      &-btn-contain {
        margin-right: 2rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2rem;
      }
      .btn {
        padding: 0.7rem;
        font-size: 0.6rem;
      }
    }
    &-box {
      height: 70vh;
    }
    &-container {
      padding: 1.8rem 3rem;
      height: 70vh;
      .vlive-faq-questions {
        padding: 1.2rem 0rem;
        p {
          font-size: 0.9rem;
          width: 72%;
        }
      }
      .vlive-faq-answers {
        p {
          font-size: 0.9rem;
          width: 72%;
          line-height: 1.2rem;
        }
      }
      .active {
        .vlive-faq-answers {
          padding: 1.2rem;
        }
      }
      .FAQ-foot {
        &-btn-contain {
          width: 25%;
          margin: 0 auto;
          button {
            font-size: 0.7rem;
            padding: 0.7rem;
          }
        }
      }
    }
  }
}
</style>
